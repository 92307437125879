<template>
  <div class="inner">
    <Navigation text="Utenze" />

    <div class="wrapper">
      <InnerHeader
        title="Utenze"
        description="Qui puoi trovare tutte le aziende a cui ti puoi rivolgere per le utenze"
      />

      <div class="bills">
        <Bill
          v-for="(bill, i) of bills"
          :key="i"
          :title="bill.title"
          :description="bill.description"
          :code="bill.code"
          :companies="bill.companies"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import InnerHeader from "@/components/InnerHeader";
import Bill from "@/components/Bill";

import { loadApartment } from "@/plugins/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  components: {
    Navigation,
    InnerHeader,
    Bill
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data: () => ({
    bills: [
      {
        title: "Sala polifunzionale",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tincidunt tincidunt maximus. Aenean ut aliquam magna. Fusce in nibh quis nulla ultricies ultrices vitae at ante. Proin eget varius ex, in molestie erat. Proin pretium lobortis tellus, sit amet maximus eros. Vestibulum quis libero purus. Sed varius sagittis gravida. Praesent vel dui fermentum, rutrum dui id, vulputate tellus. Nulla ac lacinia mi. Phasellus et convallis odio.",
        code: "SDFSDG43",
        companies: [
          {
            name: "Nome Azienda",
            type: "Rifiuti",
            logo: "//via.placeholder.com/50x50",
            email: "a@a.it",
            phone: "123424234"
          }
        ]
      },
      {
        title: "Sala polifunzionale",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tincidunt tincidunt maximus. Aenean ut aliquam magna. Fusce in nibh quis nulla ultricies ultrices vitae at ante. Proin eget varius ex, in molestie erat. Proin pretium lobortis tellus, sit amet maximus eros. Vestibulum quis libero purus. Sed varius sagittis gravida. Praesent vel dui fermentum, rutrum dui id, vulputate tellus. Nulla ac lacinia mi. Phasellus et convallis odio.",
        code: "SDFSDG43",
        companies: [
          {
            name: "Nome Azienda",
            type: "Rifiuti",
            logo: "//via.placeholder.com/50x50",
            email: "a@a.it",
            phone: "123424234"
          },
          {
            name: "Nome Azienda",
            type: "Rifiuti",
            logo: "//via.placeholder.com/50x50",
            email: "a@a.it",
            phone: "123424234"
          }
        ]
      },
      {
        title: "Sala polifunzionale",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tincidunt tincidunt maximus. Aenean ut aliquam magna. Fusce in nibh quis nulla ultricies ultrices vitae at ante. Proin eget varius ex, in molestie erat. Proin pretium lobortis tellus, sit amet maximus eros. Vestibulum quis libero purus. Sed varius sagittis gravida. Praesent vel dui fermentum, rutrum dui id, vulputate tellus. Nulla ac lacinia mi. Phasellus et convallis odio.",
        code: "SDFSDG43",
        companies: [
          {
            name: "Nome Azienda",
            type: "Rifiuti",
            logo: "//via.placeholder.com/50x50",
            email: "a@a.it",
            phone: "123424234"
          }
        ]
      }
    ]
  }),
  async beforeRouteEnter(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.bills = apartment.bills.map(bill => ({
        title: bill.fields.type,
        description: documentToHtmlString(bill.fields.description),
        code: bill.fields.userCode,
        companies: bill.fields.companies.map(company => ({
          name: company.fields.name,
          type: company.fields.type,
          logo: company.fields.logo.fields.file.url,
          email: company.fields.email,
          phone: company.fields.phone
        }))
      }));
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.bills = apartment.bills.map(bill => ({
        title: bill.fields.type,
        description: documentToHtmlString(bill.fields.description),
        code: bill.fields.userCode,
        companies: bill.fields.companies.map(company => ({
          name: company.fields.name,
          type: company.fields.type,
          logo: company.fields.logo.fields.file.url,
          email: company.fields.email,
          phone: company.fields.phone
        }))
      }));
    });
  }
};
</script>

<style lang="scss" scoped>
.inner {
  padding-bottom: 100px;
  box-sizing: border-box;

  .contact {
    margin-bottom: 66px;
  }

  .wrapper {
    box-sizing: border-box;
    padding: 0 30px;
  }

  .bills {
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 60px;
    }

    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 60px;
    }
  }
}
</style>
