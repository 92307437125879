<template>
  <div class="docs">
    <Navigation text="Contatta l’amministratore" />

    <div class="wrapper">
      <InnerHeader
        title="Contatta l’amministratore"
        description="Se ha bisogno di contattare l’amministrazione di condominio manda una mail o chiama cliccando su uno dei due pulsanti di seguito."
      />

      <h2>{{ name }}</h2>

      <div v-if="hours" class="hours">{{ hours }}</div>

      <div class="actions">
        <BigButton class="contact" text="CHIAMA" @click.native="call" />

        <BigButton
          class="contact white"
          text="MANDA UNA MAIL"
          @click.native="sendMail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import InnerHeader from "@/components/InnerHeader";
import BigButton from "@/components/BigButton";

import { loadApartment } from "@/plugins/contentful";

export default {
  components: {
    Navigation,
    InnerHeader,
    BigButton
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data: () => ({
    name: "Nome Cognome",
    hours: "Orari: da lunedì a venerdì dalle 9:00 alle 17:00",
    phone: "1312234",
    mail: "a@a.it"
  }),
  async beforeRouteEnter(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.name = apartment.condo.fields.condoAdmin.fields.name;
      vm.$data.hours = apartment.condo.fields.condoAdmin.fields.hours;
      vm.$data.phone = apartment.condo.fields.condoAdmin.fields.phone;
      vm.$data.mail = apartment.condo.fields.condoAdmin.fields.email;
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.name = apartment.condo.fields.condoAdmin.fields.name;
      vm.$data.hours = apartment.condo.fields.condoAdmin.fields.hours;
      vm.$data.phone = apartment.condo.fields.condoAdmin.fields.phone;
      vm.$data.mail = apartment.condo.fields.condoAdmin.fields.email;
    });
  },
  methods: {
    call() {
      window.open(`tel:${this.phone}`);
    },
    sendMail() {
      window.open(`mailto:${this.mail}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.docs {
  padding-bottom: 100px;
  box-sizing: border-box;

  .wrapper {
    box-sizing: border-box;
    padding: 0 30px;
  }

  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    color: #000000;
    margin-bottom: 8px;
  }

  .hours {
    font-weight: 450;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 39px;
  }

  .actions {
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 315px 315px;
      column-gap: 60px;
      justify-content: center;
    }
  }

  .contact {
    margin-bottom: 33px;

    &.white {
      background: var(--white);
      color: var(--black);
    }
  }
}
</style>
