<template>
  <div class="company" :class="{ explode }">
    <div class="data">
      <img :src="logo" :alt="name" class="logo" />

      <div class="wrap">
        <h3>{{ name }}</h3>
        <div class="type">{{ type }}</div>
      </div>
    </div>

    <div class="actions">
      <a class="mail" :href="`mailto:${email}`">Manda mail</a>
      <a class="call" :href="`tel:${phone}`">Chiama</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    explode: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style lang="scss" scoped>
.company {
  background: var(--white);
  padding: 41px 19px 38px 19px;
  position: relative;

  &:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    &::after {
      display: none;
    }
  }

  &.explode {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 40px;

    &::after {
      display: none;
    }
  }

  &::after {
    content: "";
    opacity: 0.3;
    border-bottom: 1px solid #7b7b7b;
    width: calc(100% - 19px * 2);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .data {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 19px;
    margin-bottom: 37px;

    .logo {
      display: block;
      border-radius: 10px;
    }

    .wrap {
      h3 {
        font-weight: bold;
        font-size: 17px;
        line-height: 22px;
        margin: 0;
        margin-bottom: 4px;
      }

      .type {
        font-weight: 450;
        font-size: 17px;
        line-height: 22px;
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 0 calc(34px - 19px);
    box-sizing: border-box;

    a {
      background: var(--light-brown);
      border-radius: 72px;
      text-decoration: none;
      padding: 13px 0;
      text-align: center;
      color: var(--white);
      white-space: nowrap;

      &:first-of-type {
        background: transparent;
        color: var(--black);
        opacity: 0.55;
      }
    }
  }
}
</style>
