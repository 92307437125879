<template>
  <div class="inner-header">
    <h1 v-class-on-scroll="'hide'">{{ title }}</h1>

    <div class="description">{{ description }}</div>
  </div>
</template>

<script>
export default {
  directives: {
    "class-on-scroll": {
      inserted(el, bind) {
        window.addEventListener("scroll", () => {
          el.classList.toggle(bind.value, window.scrollY > 0);
        });
      }
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: bold;
  font-size: 34px;
  line-height: 43px;
  text-align: center;
  color: var(--light-brown);
  transition: opacity 1s ease-in-out;

  @media screen and (min-width: 768px) {
    font-size: 45px;
    line-height: 57px;
  }

  &.hide {
    opacity: 0;
  }
}

.description {
  color: #000000;
  opacity: 0.55;
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 94px;

  @media screen and (min-width: 768px) {
    font-size: 15px;
    line-height: 19px;
  }
}
</style>
