<template>
  <div class="news">{{ text }}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.news {
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: var(--light-brown);
  padding: 0 22px;

  @media screen and (min-width: 768px) {
    font-size: 15px;
    line-height: 19px;
  }
}
</style>
