<template>
  <div class="inner">
    <Navigation text="Fornitori" />

    <div class="wrapper">
      <InnerHeader
        title="Fornitori"
        description="Di seguito troverai l’elenco di tutte le aziende che hanno contribuito alla realizzazione di questo edificio."
      />

      <div class="suppliers">
        <CompanyCard
          v-for="(company, i) of companies"
          :key="i"
          :name="company.name"
          :type="company.type"
          :logo="company.logo"
          :email="company.email"
          :phone="company.phone"
          :explode="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import InnerHeader from "@/components/InnerHeader";
import CompanyCard from "@/components/CompanyCard";

import { loadApartment } from "@/plugins/contentful";

export default {
  components: {
    Navigation,
    InnerHeader,
    CompanyCard
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data: () => ({
    companies: [
      {
        name: "Nome Azienda",
        type: "Rifiuti",
        logo: "//via.placeholder.com/50x50",
        email: "a@a.it",
        phone: "123424234"
      },
      {
        name: "Nome Azienda",
        type: "Rifiuti",
        logo: "//via.placeholder.com/50x50",
        email: "a@a.it",
        phone: "123424234"
      },
      {
        name: "Nome Azienda",
        type: "Rifiuti",
        logo: "//via.placeholder.com/50x50",
        email: "a@a.it",
        phone: "123424234"
      },
      {
        name: "Nome Azienda",
        type: "Rifiuti",
        logo: "//via.placeholder.com/50x50",
        email: "a@a.it",
        phone: "123424234"
      }
    ]
  }),
  async beforeRouteEnter(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.companies = apartment.condo.fields.suppliers.map(company => ({
        name: company.fields.name,
        type: company.fields.type,
        logo: company.fields.logo.fields.file.url,
        email: company.fields.email,
        phone: company.fields.phone
      }));
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.companies = apartment.condo.fields.suppliers.map(company => ({
        name: company.fields.name,
        type: company.fields.type,
        logo: company.fields.logo.fields.file.url,
        email: company.fields.email,
        phone: company.fields.phone
      }));
    });
  }
};
</script>

<style lang="scss" scoped>
.inner {
  padding-bottom: 100px;
  box-sizing: border-box;

  .contact {
    margin-bottom: 66px;
  }

  .wrapper {
    box-sizing: border-box;
    padding: 0 30px;
  }

  .suppliers {
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 60px;
    }

    @media screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 60px;
    }
  }
}
</style>
