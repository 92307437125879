<template>
  <div class="emotional-block" :class="{ inverted }">
    <h2>{{ title }}</h2>

    <picture class="picture">
      <img :src="image" />
    </picture>

    <div class="text-wrap">
      <h2>{{ title }}</h2>

      <div v-html="text" class="text"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    image: {
      type: String,
      default: () => "//via.placeholder.com/330x205"
    },
    inverted: {
      type: Boolean,
      default: () => true
    }
  }
};
</script>

<style lang="scss" scoped>
.emotional-block {
  margin-bottom: 100px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 90px;
    justify-content: center;
    align-items: center;

    &.inverted {
      .picture {
        order: 1;
      }
    }
  }

  h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    margin: 0;
    margin-bottom: 46px;
    color: #000000;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .picture {
    display: block;
    width: 100%;
    margin-bottom: 30px;

    img {
      display: block;
      width: 100%;
      border-radius: 20px;
    }
  }

  .text-wrap {
    h2 {
      display: none;
      text-align: left;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    .text {
      font-weight: 450;
      font-size: 15px;
      line-height: 138.84%;
      letter-spacing: 0.02em;
      padding: 0 5px;
    }
  }
}
</style>
