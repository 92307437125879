<template>
  <div class="sections" :class="`columns-${sections.length}`">
    <div v-for="(section, i) of sections" :key="i" class="section">
      <div class="title">{{ section.title }}</div>
      <ul class="docs">
        <li v-for="(doc, j) of section.docs" :key="j" class="doc">
          <a :href="doc.url">
            <span class="text">{{ doc.text }}</span>
            <img :src="require('./down.svg')" class="icon" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      default: () => [
        {
          title: "Titolo sezione",
          docs: [
            {
              url: "google.com",
              text: "Rogito notarile"
            },
            {
              url: "google.com",
              text: "Titolo autorizzativo"
            },
            {
              url: "google.com",
              text: "Agibilità"
            }
          ]
        },
        {
          title: "Titolo sezione",
          docs: [
            {
              url: "google.com",
              text: "Rogito notarile"
            },
            {
              url: "google.com",
              text: "Titolo autorizzativo"
            },
            {
              url: "google.com",
              text: "Agibilità"
            }
          ]
        },
        {
          title: "Titolo sezione",
          docs: [
            {
              url: "google.com",
              text: "Rogito notarile"
            },
            {
              url: "google.com",
              text: "Titolo autorizzativo"
            }
          ]
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.sections {
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;

    &.columns-1 {
      grid-template-columns: 1fr;
    }

    &.columns-2 {
      grid-template-columns: 2fr;
    }
  }

  .section {
    margin-bottom: 50px;

    .title {
      font-weight: bold;
      font-size: 17px;
      line-height: 22px;
      color: black;
      margin-bottom: 26px;
    }

    .docs {
      list-style: none;
      margin: 0;
      padding: 0;
      background: white;
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
      border-radius: 20px;
      padding: 18px 19px;

      .doc {
        &:last-of-type {
          a {
            &::after {
              display: none;
            }
          }
        }

        a {
          color: var(--black);
          text-decoration: none;
          display: grid;
          grid-template-columns: 1fr auto;
          padding: 17px 0;
          padding-right: calc(27px - 19px);
          position: relative;
          align-items: center;

          .text {
            font-weight: 450;
            font-size: 17px;
            line-height: 22px;
            padding-left: calc(24px - 19px);
            display: flex;
            align-items: center;
          }

          .icon {
            display: block;
          }

          &::after {
            content: "";
            opacity: 0.3;
            border: 1px solid #7b7b7b;
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
