<template>
  <div class="bill">
    <h2>{{ title }}</h2>

    <div v-if="description" v-html="description" class="description"></div>

    <div v-if="code" class="code">Codice utente: {{ code }}</div>

    <div class="companies">
      <CompanyCard
        v-for="(company, i) of companies"
        :key="i"
        :name="company.name"
        :type="company.type"
        :logo="company.logo"
        :email="company.email"
        :phone="company.phone"
      />
    </div>
  </div>
</template>

<script>
import CompanyCard from "@/components/CompanyCard";

export default {
  components: { CompanyCard },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: () => ""
    },
    code: {
      type: String,
      default: () => ""
    },
    companies: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.bill {
  margin-bottom: 50px;

  h2 {
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: black;
    margin-bottom: 22px;
  }

  .description {
    font-weight: 450;
    font-size: 15px;
    line-height: 138.84%;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
  }

  .code {
    font-weight: bold;
    font-size: 15px;
    line-height: 138.84%;
    letter-spacing: 0.02em;
    color: var(--light-brown);
    margin-bottom: 37px;
  }
}
</style>
