<template>
  <router-link class="icon-link" :to="to">
    <div class="icon">
      <img :src="require(`./${icon}.svg`)" alt="" />
    </div>

    <div class="text">{{ text }}</div>
  </router-link>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: () => "doc"
    },
    text: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-link {
  display: block;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  background: var(--white);
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 32px 20px 18px 20px;
  position: relative;
  text-decoration: none;

  @media screen and (min-width: 768px) {
    padding: 32px 0 18px 0;
    overflow: hidden;
  }

  .icon {
    width: 46px;
    height: 46px;
    background: var(--light-brown);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    top: 0;
    position: absolute;

    @media screen and (min-width: 768px) {
      width: 100%;
      height: 66px;
      border-radius: 0;
      position: relative;
    }

    img {
      display: block;

      @media screen and (min-width: 768px) {
        height: 40px;
      }
    }
  }

  .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 129.5%;
    color: var(--black);
    min-height: calc(16px * 2 * 1.29);
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
      font-size: 25px;
      line-height: 129.5%;
      min-height: calc(25px * 2 * 1.29);
      padding: 0 18px;
    }
  }
}
</style>
