<template>
  <div class="video-section">
    <h2>{{ title }}</h2>

    <div v-if="description.length > 0" class="description">
      {{ description }}
    </div>

    <div class="inner">
      <div v-if="video.length > 0" class="video">
        <video controls>
          <source :src="video" type="video/mp4" />
        </video>
      </div>

      <ul class="docs">
        <li v-for="(doc, i) of docs" :key="i" class="doc">
          <a :href="doc.url">
            <span class="text">{{ doc.name }}</span>
            <img :src="require('./down.svg')" class="icon" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: () => ""
    },
    video: {
      type: String,
      default: () => ""
    },
    docs: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.video-section {
  h2 {
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: black;
    margin-bottom: 26px;
  }

  .description {
    margin-bottom: 26px;
  }

  .inner {
    background: #ffffff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 54px;

    .video {
      padding: 56.25% 0 0 0;
      position: relative;

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .docs {
      list-style: none;
      margin: 0;
      padding: 0;
      background: white;
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
      border-radius: 20px;
      padding: 18px 19px;

      .doc {
        &:last-of-type {
          a {
            &::after {
              display: none;
            }
          }
        }

        a {
          color: var(--black);
          text-decoration: none;
          display: grid;
          grid-template-columns: 1fr auto;
          padding: 17px 0;
          padding-right: calc(27px - 19px);
          position: relative;

          .text {
            font-weight: 450;
            font-size: 17px;
            line-height: 22px;
            padding-left: calc(24px - 19px);
            display: flex;
            align-items: center;
          }

          .icon {
            display: block;
          }

          &::after {
            content: "";
            opacity: 0.3;
            border: 1px solid #7b7b7b;
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
