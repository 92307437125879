<template>
  <div class="docs">
    <Navigation text="Documenti amministrativi" />

    <div class="wrapper">
      <InnerHeader
        title="Documenti amministrativi"
        description="Di seguito trovi tutti i documenti amministrativi legati alla tua abitazione e all’acquisto."
      />

      <DocList :sections="docs" />
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import InnerHeader from "@/components/InnerHeader";
import DocList from "@/components/DocList";

import { loadApartment } from "@/plugins/contentful";

export default {
  components: {
    Navigation,
    InnerHeader,
    DocList
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data: () => ({
    docs: []
  }),
  async beforeRouteEnter(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.docs = apartment.adminDocs
        .map(q => q.fields)
        .map(section => ({
          title: section.title,
          docs: section.docs.map(doc => ({
            url: doc.fields.file.url,
            text: doc.fields.title
          }))
        }));
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.docs = apartment.adminDocs
        .map(q => q.fields)
        .map(section => ({
          title: section.title,
          docs: section.docs.map(doc => ({
            url: doc.fields.file.url,
            text: doc.fields.title
          }))
        }));
    });
  }
};
</script>

<style lang="scss" scoped>
.docs {
  padding-bottom: 100px;

  .wrapper {
    box-sizing: border-box;
    padding: 0 30px;
  }
}
</style>
