<template>
  <div class="code">
    <CondoHeader
      :logo="condo.logo"
      :name="condo.name"
      :address="condo.address"
      class="header"
    />

    <LatestNews v-if="condo.latestNews" :text="condo.latestNews" />

    <div class="grid">
      <IconLink
        icon="doc"
        text="Documenti amministrativi"
        :to="`/${code}/documenti-amministrativi`"
      />
      <IconLink icon="condo" text="Condominio" :to="`/${code}/condominio`" />
      <IconLink
        icon="settings"
        text="Uso e manutenzione"
        :to="`/${code}/uso-manutenzione`"
      />
      <IconLink icon="zap" text="Utenze" :to="`/${code}/utenze`" />
      <IconLink icon="hammer" text="Fornitori" :to="`/${code}/fornitori`" />
      <IconLink
        icon="phone"
        text="Contatta amministratore"
        :to="`/${code}/contatta-amministratore`"
      />
    </div>

    <router-link :to="'/'" class="disconnect">Disconnetti</router-link>
  </div>
</template>

<script>
import CondoHeader from "@/components/CondoHeader";
import IconLink from "@/components/IconLink";
import LatestNews from "@/components/LatestNews";

import { loadApartment } from "@/plugins/contentful";

export default {
  components: { CondoHeader, IconLink, LatestNews },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data: () => ({
    condo: {
      logo: "//via.placeholder.com/55x55",
      name: "Condo",
      address: "via Padova 67 - Vigonza",
      latestNews: "ddwv"
    }
  }),
  async beforeRouteEnter(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.condo = {
        latestNews: apartment.condo.fields.globalMessage,
        logo: apartment.condo.fields.logo.fields.file.url,
        name: apartment.condo.fields.name,
        address: apartment.condo.fields.address
      };
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const apartment = await loadApartment(to.params.code);
    next(vm => {
      vm.$data.condo = {
        latestNews: apartment.condo.fields.globalMessage,
        logo: apartment.condo.fields.logo.fields.url,
        name: apartment.condo.fields.name,
        address: apartment.condo.fields.address
      };
    });
  }
};
</script>

<style lang="scss" scoped>
.code {
  padding-bottom: 29px;
  box-sizing: border-box;
}

.header {
  margin-bottom: 14px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  row-gap: 46px;
  column-gap: 18px;
  padding: 45px 15px;
  padding-bottom: 38px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.disconnect {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #d26152;
  text-decoration: none;
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
}
</style>
