<template>
  <div class="condo-header">
    <div class="info">
      <img :src="logo" :alt="name" class="logo" />

      <div class="inner-info">
        <div class="name">{{ name }}</div>
        <div class="address">{{ address }}</div>
      </div>
    </div>

    <div class="disconnect-wrap">
      <router-link :to="'/'" tag="button" class="disconnect"
        >Disconnetti</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.condo-header {
  padding-top: 19px;
  padding-bottom: 22px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr auto;
    padding-top: 50px;
    padding-left: 90px;
    padding-bottom: 46px;
    padding-right: 52px;
  }

  .info {
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 78px;
      justify-content: center;
      align-items: center;
    }

    .logo {
      margin: 0 auto;
      display: block;
      height: 55px;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        height: 89px;
        margin-bottom: 0;
      }
    }

    .inner-info {
      .name {
        font-weight: bold;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 7px;

        @media screen and (min-width: 768px) {
          text-align: left;
          margin-bottom: 17px;
          font-size: 30px;
          line-height: 38px;
        }
      }

      .address {
        font-weight: 450;
        font-size: 15px;
        line-height: 19px;
        text-align: center;

        @media screen and (min-width: 768px) {
          text-align: left;
          font-size: 22px;
          line-height: 28px;
          white-space: nowrap;
        }
      }
    }
  }

  .disconnect-wrap {
    display: none;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
      display: flex;
    }

    .disconnect {
      border: 2px solid #d26152;
      box-sizing: border-box;
      border-radius: 72px;
      background: transparent;
      color: #d26152;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      padding: 18px 28px;
    }
  }
}
</style>
